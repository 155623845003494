/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Confirm from 'vux/src/components/confirm';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
import Datetime from 'vux/src/components/datetime';
import PopupPicker from 'vux/src/components/popup-picker';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Toast } from 'vant';
import UploadImage from '@/components/UploadImage/UploadImageNew';
import atitle from '@/components/vis/Title02';
export default {
  name: 'VisitorRegister',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    XInput: XInput,
    Group: Group,
    Cell: Cell,
    XButton: XButton,
    Datetime: Datetime,
    PopupPicker: PopupPicker,
    Confirm: Confirm,
    UploadImage: UploadImage,
    atitle: atitle
  },
  data: function data() {
    return {
      colorFlag: false,
      loading: false,
      finished: false,
      Queryshow: false,
      //是否展开人员查询
      companyId: '',
      visitor: '',
      //来访人姓名
      visitorTel: '',
      //来访人电话
      identity: '',
      //来访人身份证号
      sexList: [{
        name: '男',
        value: '1'
      }, {
        name: '女',
        value: '2'
      }],
      //性别数组
      sex: ['1'],
      //来访人性别
      company: '',
      //来访人工作单位
      receiver: '',
      //被访人姓名
      receiverTel: '',
      //被访人电话
      followNo: '',
      //随访人数
      carNo: '',
      //来访人车辆号码
      hasCar: false,
      //是否有随行车辆
      kssj: '',
      //到访时间
      jssj: '',
      //结束时间
      reasonList: [],
      reason: [],
      //到访事由
      remark: '',
      //备注
      showOperate: false,
      //是否显示操作弹窗
      manList: [{
        userLname: 'xiaobai',
        userTelephone: '18396822168',
        userDepname: '企业'
      }, {
        userLname: 'xiaobai',
        userTelephone: '18396822168',
        userDepname: '企业'
      }, {
        userLname: 'xiaobai',
        userTelephone: '18396822168',
        userDepname: '企业'
      }],
      selected: 0,
      //选择的被访人，默认选择第一个
      icon: 'icon-renlian',
      //icon人脸
      uploadIcon: 'icon-jiahao',
      //上传附件
      limitNum: 1,
      //上传的图片张数
      imgList: [],
      //图片列表数组
      visOpenId: this.$utils.Store.getItem('visOpenId') ? this.$utils.Store.getItem('visOpenId') : '',
      //'oqxv60tbQVZYvqF3KbYlnKJF9UNc',       //
      startDate: '',
      userSerial: '',
      curTime: '',
      //当前时间
      visitType: '',
      btnflag: true,
      //是否可以按下。
      pagination: {
        total: 23,
        pageIndex: 1,
        pageSize: 20
      },
      recordsList: [],
      //模糊查询的字段
      mySearch: '',
      ftext: '没有更多了',
      isPhone: false,
      //手机号必填项校验
      uploadNum: 4,
      uploadImgList: []
    };
  },
  computed: {
    btnDis: function btnDis() {
      if (this.visitor != '' && this.visitorTel != '' && this.receiver != '' && this.reason[0] && this.btnflag) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    onShow: function onShow() {},
    onHide: function onHide() {},
    saveImages: function saveImages() {},
    deleteImage: function deleteImage() {},
    // 手机号码
    strPhone: function strPhone(str) {
      if (str) {
        var reg = /^(\d{3})\d*(\d{4})$/;
        return str.replace(reg, '$1****$2');
      } else {
        return '****';
      }
    },
    // 关闭面板
    onclose: function onclose() {
      this.pagination = {
        total: 23,
        pageIndex: 1,
        pageSize: 20
      };
      this.mySearch = '';
      this.colorFlag = false;
      this.recordsList = [];
      this.Queryshow = false;
      this.finished = true;
      this.ftext = '';
      this.loading = false;
    },
    onSearch: function onSearch() {
      if (this.$utils.RegExp.checkSpechars(this.mySearch) || this.$utils.RegExp.checkEmpty(this.mySearch)) {
        Toast('被访人姓名不能为空或包含特殊字符');
        return;
      }

      this.pagination = {
        total: 23,
        pageIndex: 1,
        pageSize: 20
      };
      this.recordsList = [];
      this.onLoad();
      this.finished = false;
    },
    // 查询人员时候的列表
    onLoad: function onLoad() {
      var _this = this;

      if (this.mySearch == '') return;
      this.colorFlag = false;
      this.loading = true;
      setTimeout(function () {
        _this.selectVisUserByDept();

        _this.pagination.pageIndex = _this.pagination.pageIndex + 1; // 数据全部加载完成

        if (_this.recordsList.length >= _this.pagination.total) {
          _this.finished = true;
          _this.ftext = '没有更多了';
          return;
        }
      }, 1000);
    },

    /**
     *  function获取人员名字
     *  @lwt
     *  @2020-12-15
     **/
    selectVisUserByDept: function selectVisUserByDept() {
      var _this2 = this;

      if (this.$utils.RegExp.checkSpechars(this.mySearch) || this.$utils.RegExp.checkEmpty(this.mySearch)) {
        return;
      }

      this.$api.User.selectVisUserByDept({
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
        search: {
          userDeps: '',
          keys: this.mySearch,
          searchFlag: 1
        }
      }).then(function (response) {
        // 加载状态结束
        _this2.loading = false;
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          Toast(msg);
        } else {
          _this2.pagination.total = data.totalRecord;

          if (_this2.recordsList.length < _this2.pagination.total) {
            var _this2$recordsList;

            (_this2$recordsList = _this2.recordsList).push.apply(_this2$recordsList, _toConsumableArray(data.records));
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getDay: function getDay(day) {
      var today = new Date(this.startDate);
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    getVisitDate: function getVisitDate() {
      var _this3 = this;

      this.$api.Visit.getVistorTimeStting().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          Toast(msg); // this.$utils.Tools.toastShow(msg);
        } else {
          //1设置时间 0 当前时间
          if (data.switchOn == 1) {
            _this3.kssj = _this3.startDate + " " + data.beginTime;
            _this3.jssj = _this3.startDate + " " + data.endTime;
          } else {
            _this3.kssj = _this3.curTime;
            _this3.jssj = _this3.startDate + " " + data.endTime;
          }
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this4 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          Toast(msg);
        } else {
          _this4.startDate = data.sysDate.substring(0, 10);
          _this4.curTime = data.sysDate.substring(0, 16);

          _this4.getVisitDate();
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /**
     *  function获取来访事由列表
     *  @wzx
     *  @2019-04-01
     **/
    getReasonList: function getReasonList() {
      var _this5 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.getReasonList({
        lx: 0,
        companyId: this.companyId
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          Toast(msg);
        } else {
          var _iterator = _createForOfIteratorHelper(data),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;

              _this5.reasonList.push({
                value: JSON.stringify(item.xh),
                name: item.thingName
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    checkVisitor: function checkVisitor() {
      if (!this.$utils.RegExp.checkSpechars(this.visitor)) {
        Toast('来访人姓名不能为空或有特殊字符');
      }
    },
    changeStartTime: function changeStartTime(val) {
      this.kssj = val;
    },

    /***
     * 判断手机号是否做必填项校验
     *
     */
    getPhone: function getPhone() {
      var _this6 = this;

      this.$api.Visit.getPhoneConfig().then(function (res) {
        if (res.data == 'true') {
          _this6.isPhone = true;
        } else {
          _this6.isPhone = false;
        }
      });
    },
    getWxOpenIdByCode: function getWxOpenIdByCode() {
      var _this7 = this;

      this.$utils.Tools.loadShow();
      this.$api.User.getWxOpenIdByCode(this.inviteCode, this.companyId).then(function (response) {
        _this7.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this7.$code.success) {
          Toast(msg);
          setTimeout(function () {
            window.WeixinJSBridge.call('closeWindow');
          }, 2000);
        } else {
          _this7.visOpenId = data;

          _this7.$utils.Store.setItem('visOpenId', data);

          _this7.getVisitorInfo();
        }
      }).catch(function () {
        _this7.$utils.Tools.httpError();
      });
    },

    /**
     *  function获取访客基本信息
     *  @wzx
     *  @2019-04-01
     **/
    getVisitorInfo: function getVisitorInfo() {
      var _this8 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.getVisitorInfo(this.visOpenId, this.companyId).then(function (response) {
        _this8.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this8.$code.success) {
          Toast(msg);
        } else {
          _this8.identity = data.visId;
          _this8.visitor = data.visLname;
          _this8.company = data.compName || '个人';
          _this8.sex = [data.visSex];
          _this8.visitorTel = data.visTelephone;

          if (data.facePhoto) {
            _this8.imgList.push({
              src: data.facePhoto,
              msrc: data.facePhoto
            });
          }
        }
      }).catch(function () {
        _this8.$utils.Tools.httpError();
      });
    },
    move: function move() {
      //输入法键盘冒起时上移输入框使其不被键盘覆盖
      setTimeout(function () {
        document.querySelector('#Login').scrollTop = 170;
      }, 700);
    },
    followBlur: function followBlur() {// document.body && (document.body.scrollTop = document.body.scrollTop);
    },

    /**
     *  function新增访客预约信息
     *  @wzx
     *  @2019-04-01
     **/
    addVisitorOrder: function addVisitorOrder() {
      var _this9 = this;

      this.$utils.Tools.loadShow();
      var facePhoto = '';
      this.btnflag = false;

      if (this.imgList.length > 0) {
        if (this.imgList[0].serverId) {
          facePhoto = this.imgList[0].serverId;
        } else if (this.imgList[0].src) {
          facePhoto = '-1';
        }
      }

      this.$api.Visit.addVisitorOrder({
        companyId: this.companyId,
        type: '1',
        visRegisterEntity: {
          approveState: 0,
          carState: this.hasCar ? '1' : '0',
          userSerial: this.userSerial,
          userLname: this.receiver,
          telePhone: this.receiverTel,
          visBz: this.remark,
          visCars: this.carNo,
          visKssj: this.kssj,
          visJssj: this.jssj,
          visNums: this.followNo,
          visThing: this.reason[0],
          visLx: 1,
          visOk: 1
        },
        visUserEntity: {
          compName: this.company,
          facePhoto: facePhoto,
          openId: this.visOpenId,
          visId: this.identity,
          visLname: this.visitor,
          visSex: this.sex[0],
          visTelephone: this.visitorTel,
          visFlag: 3,
          cardPhoto: '-1'
        }
      }).then(function (response) {
        _this9.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;
        _this9.btnflag = true;

        if (+code !== _this9.$code.success) {
          if (code === 610) {
            _this9.$router.replace({
              path: '/RegisterResult',
              query: {
                state: '2'
              }
            }); //被拒绝,state必须为string类型

          } else if (code === '613') {
            var _this9$manList;

            Toast(msg);
            _this9.manList = [];

            (_this9$manList = _this9.manList).push.apply(_this9$manList, _toConsumableArray(data));

            _this9.showOperate = true;
          } else {
            Toast(msg);
          }
        } else {
          _this9.$router.replace({
            path: '/RegisterResult',
            query: {
              state: '0'
            }
          }); //等待,state必须为string类型

        }
      }).catch(function () {
        _this9.$utils.Tools.httpError();
      });
    },
    registerConfirm: function registerConfirm() {
      var that = this;
      this.$vux.confirm.show({
        title: '提示信息',
        content: '<p>请确认是否为本人身份证信息</p><p>并提交申请</p><br>',
        onConfirm: function onConfirm() {
          that.registerClick();
        }
      });
    },

    /**
     *  function新增访客预约信息
     *  @wzx
     *  @2019-04-01
     **/
    registerClick: function registerClick() {
      if (this.$utils.RegExp.checkSpechars(this.visitor) || this.$utils.RegExp.checkEmpty(this.visitor)) {
        Toast('来访人姓名不能为空或包含特殊字符');
      } else if (this.$utils.RegExp.checkPhone(this.visitorTel)) {
        Toast('来访人手机号格式不正确或为空');
      } else if (!this.$utils.RegExp.checkCardNO(this.identity) || this.$utils.RegExp.checkEmpty(this.identity)) {
        Toast('身份证号码不能为空或不合法');
      } else if (this.$utils.RegExp.checkSpechars(this.company)) {
        Toast('工作单位不能包含特殊字符');
      } else if (this.$utils.RegExp.checkSpechars(this.receiver) || this.$utils.RegExp.checkEmpty(this.receiver)) {
        Toast('被访人姓名不能为空或包含特殊字符');
      } else if (this.receiverTel && this.$utils.RegExp.checkPhone(this.receiverTel)) {
        Toast('被访人手机号格式不正确');
      } else if (this.followNo && !this.$utils.RegExp.checkNumber(this.followNo)) {
        Toast('随访人数必须为1-2位的正整数');
      } else if (this.carNo && !this.$utils.RegExp.checkCarNO(this.carNo)) {
        Toast('随访车牌号不合法或为空');
      } else if (this.$utils.RegExp.checkEmpty(this.kssj)) {
        Toast('到访时间不能为空'); // } else if (this.kssj < this.curTime) {
        //   this.$utils.Tools.toastShow('预约开始时间不能小于当前时间');
      } else if (this.$utils.RegExp.checkEmpty(this.jssj)) {
        Toast('结束时间不能为空'); // } else if (this.kssj < this.curTime) {
        //   this.$utils.Tools.toastShow('预约结束时间不能小于当前时间');
      } else if (this.jssj <= this.kssj) {
        Toast('结束时间必须大于到访时间');
      } else if (this.remark && this.$utils.RegExp.checkSpechars(this.remark)) {
        Toast('备注不能包含特殊字符');
      } else if (this.reason.length === 0) {
        Toast('请选择来访事由');
      } else if (this.imgList.length === 0) {
        Toast('请录入人脸照片');
      } else if (this.isPhone && !this.receiverTel) {
        Toast('请输入被访人联系电话');
      } else {
        this.addVisitorOrder();
      }
    },
    manClick: function manClick(obj, index) {
      this.selected = index;
      this.receiver = obj.userLname;
      this.receiverTel = obj.userTelephone;
      this.userSerial = obj.userSerial;
    },
    onOperate: function onOperate() {
      if (this.receiverTel === '') {
        this.receiverTel = this.manList[0].userTelephone;
        this.userSerial = this.manList[0].userSerial;
      }

      this.showOperate = false;
    },
    getVisitType: function getVisitType() {
      var _this10 = this;

      this.$api.Visit.getVisitType().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== 600) {
          Toast(msg);
        } else {
          _this10.visitType = data;
        }
      }).catch(function () {
        _this10.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.companyId = this.$route.query.companyId;
  },
  mounted: function mounted() {
    if (!this.$utils.Store.getItem('visOpenId')) {
      this.inviteCode = this.$utils.Tools.getQueryString('code');
      this.getWxOpenIdByCode();
    } else {
      this.getVisitorInfo();
    }

    this.getSysDate();
    this.getReasonList();
    this.getVisitType();
    this.getPhone();
  },
  destroyed: function destroyed() {}
};